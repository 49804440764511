<template>
  <a-modal :title="'数据字典'" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" v-bind="formLayout">
			<a-form-item label="隐藏字段" style="display:none;">
				<a-input v-decorator="[ 'pid' ]" />
			</a-form-item>
      <a-form-item label="字典名称">
        <a-input v-decorator="[ 'title', { rules: [{ required: true, message: '' }] }]" />
      </a-form-item>
			<a-form-item label="字典排序">
			  <a-input type="number" v-decorator="[ 'sort', { initialValue:0, rules: [{ required: true, message: '' }] }]" />
			</a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick";

// 表单字段
const fields = ["title", "pid", "sort", "type"];

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
    };
    return {
      form: this.$form.createForm(this),
			pid:0,
			attr:[]
    };
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v));
  },
  methods: {
    init(e) {
      this.form.setFieldsValue(pick(e, fields))
      this.visible = true
			this.pid = e.pid
			this.attr = JSON.parse(localStorage.getItem('attr1'))
    },
    cancel() {
			this.form.resetFields();
      this.visible = false;
    },
    ok() {
      this.form.validateFields((errors, values) => {
				this.form.resetFields();
        if (!errors) this.$emit("ok", values);
      });
    },
  },
}
</script>
